<template>
  <ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="contractMode.value !== 0 ? parseTableAction({ actionType: 'create-contract' }) : ''">
    <BPCard
      :contractMode.sync="contractMode"
      :headerConfig="headerConfig"
      dataTestId="contract-table"
    >
      <ContractTable
        :contracts="allContracts"
        :isTractDetail="false"
        :contractMode="contractMode"
        @refresh-contracts="refreshContracts"
        @contract-action="parseTableAction"
      />
      <Dialog :stateId="dialogId"
        :enforcePersistence="requireEditApproval"
      >
        <ContractCreator
          :contractMode="contractMode"
          v-if="creating"
          @contract-changed="save"
        />
        <EditContract
          v-if="editing"
          :contractId="focusedContract.contractId"
          :contractMode="contractMode"
          :shouldAddActivity="shouldAddActivity"
          :companyRequiresEditApproval="companyInfo.requireApprovalForContractModifications"
          :currentDraftMode="focusedContract.isDraft"
          @contract-changed="save"
          @close="closeEditor"
        />
        <AddWithBundleDialog
          v-if="bundleDialog"
          :contractId="focusedContract.contractId"
          :bundle="focusedBundle"
          :contractMode="contractMode"
          @added-activities-close="closeBundleDialog(true)"
          @close="closeBundleDialog(false)"
        />
        <ContractDetail
          @edit-activities="editActivities"
          @edit-contract="editContract"
          v-if="contractDetail"
          :contractId="focusedContract.contractId"
          :hasPendingTickets="focusedContract ? focusedContract.hasPendingTickets : false"
          @review="refreshContracts"
        />
        <TractDetail
          :tractId="focusedTractId"
          v-if="focusedTractId != -1"
          @edit-tract="editTract"
        />
        <ImportContractTickets
          v-if="importingByproductTickets"
          :contractMode="contractMode"
          :isUpdate="isTicketUpdate"
          @tickets-uploaded="refreshContracts"
          @close="resetDialogs"
        />
        <ConfirmDelete
          v-if="deleting"
          title="Contract"
          @delete="deleteItem"
          @cancel-delete="resetDialogs"/>
      </Dialog>
    </BPCard>
  </ShortcutWrapper>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { CookieKeys } from '@/utils/constants.js'
import { ContractMode } from '@/utils/Enumerations.js'
import Cookies from 'js-cookie'
import { SETTING_KEYS, SETTING_TYPES } from '@/utils/UserSettings'
import RouterJump from '@/model/RouterJump.js'
import { uniqueDialogId } from '../utils/componentHelpers'
import { UserClaims } from '../utils/Enumerations'
export default {
  name: 'Contracts',

  components: {
    BPCard: () => import('@/components/core/BPCard.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    ContractCreator: () => import('@/components/contract/ContractCreator.vue'),
    EditContract: () => import('@/components/contract/ContractEditor.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    ContractTable: () => import('@/components/contract/ContractTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    AddWithBundleDialog: () => import('@/components/contract/AddWithBundleDialog.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    ImportContractTickets: () => import('@/components/contract/import-tickets/ImportContractTickets.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('contracts'),
    deleting: false,
    contractDetail: false,
    creating: false,
    editing: false,
    shouldAddActivity: false,
    isTicketUpdate: false,
    focusedTractId: -1,
    focusedContract: {},
    contractMode: null,
    focuseBundle: undefined,
    bundleDialog: false,
    importingByproductTickets: false,
    loading: null,
    UserClaims
  }),

  async created () {
    this.loading = true
    const contractMode = Cookies.get(CookieKeys.CONTRACT_GRID_TICKET_MODE)

    try {
      this.contractMode = ContractMode.forInt(JSON.parse(contractMode).value) ?? ContractMode.Logs
    } catch {
      this.contractMode = ContractMode.Logs
    }
    await this.initializeFilter()
    const filterObj = {
      ...this.filter,
      ...this.userSettingFilterParams(this.mutatedUserSettings)
    }
    this.setFilter(filterObj)

    this.initializeFilter()
  },

  async mounted () {
    this.resetDialogs()
    await this.handleRouterJump()
    this.loading = false
  },

  beforeDestroy () {
    Cookies.set(CookieKeys.CONTRACT_GRID_TICKET_MODE, JSON.stringify(this.contractMode))
  },

  watch: {
    contractMode (val, oldval) {
      Cookies.set(CookieKeys.CONTRACT_GRID_TICKET_MODE, JSON.stringify(this.contractMode))
      if (oldval === null) return

      this.setFilterForContractMode(val)
    },

    routerJump: {
      handler () {
        if (this.loading === false) {
          this.handleRouterJump()
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters('contract', ['allContracts', 'filter']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    ...mapGetters('global', ['routerJump']),
    ...mapGetters('user', ['companyInfo']),

    headerConfig () {
      switch (this.contractMode?.value) {
        case ContractMode.Logs.value: return { title: this.$t('logsContracts'), subtitle: this.$t('logsContractsDescription') }
        case ContractMode.Byproducts.value: return { title: this.$t('byproductContracts'), subtitle: this.$t('byproductContractsDescription') }
        case ContractMode.Transfers.value: return { title: this.$t('transferContracts'), subtitle: this.$t('transferContractsDescription') }
        case ContractMode.LogYardSale.value: return { title: this.$t('logYardSaleContracts'), subtitle: this.$t('logYardSaleContractsDescription') }
      }

      return null
    },

    requireEditApproval () {
      return this.companyInfo.requireApprovalForContractModifications && !this.focusedContract.isDraft && this.editing
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),
    ...mapActions('contract', ['fetchContracts', 'deleteContract', 'initializeFilter', 'setFilter', 'getContract']),
    async handleRouterJump () {
      if (this.routerJump) {
        let { contract, editActivities, contractId } = this.routerJump.sourceObject
        if (!contract && contractId !== undefined) {
          contract = await this.getContract(contractId)
        }

        switch (this.routerJump.sourceView) {
          case 'Tickets':
            this.shouldAddActivity = editActivities
            this.focusedContract = contract
            this.editing = true
            this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
            break
          case 'Toolbar':
            this.parseTableAction({
              actionType: 'contract-detail',
              contract: await this.getContract(this.routerJump.sourceObject.contractId)
            })
            break
        }
      }
      this.setRouterJump(undefined)
    },

    editTract () {
      const routerJump = new RouterJump('Tickets', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    parseTableAction (action) {
      this.resetDialogs()

      if (action.contract) {
        this.focusedContract = action.contract
      }

      switch (action.actionType) {
        case 'import-tickets':
          this.importingByproductTickets = true
          this.isTicketUpdate = action.isUpdate
          this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
          break
        case 'edit-contract':
          this.editing = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'view-tract':
          this.focusedTractId = this.focusedContract.tractId
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'contract-detail':
          this.contractDetail = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'create-contract':
          this.creating = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'delete-contract':
          this.deleting = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
      }
    },

    async refreshContracts () {
      await this.fetchContracts(this.filter)
    },

    closeEditor () {
      this.resetDialogs()
      this.refreshContracts()
    },

    editContract (contract) {
      this.resetDialogs()
      this.focusedContract = Object.assign({}, contract)
      this.editing = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    editActivities (contract) {
      this.resetDialogs()
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
      this.focusedContract = Object.assign({}, contract)
      this.shouldAddActivity = true
      this.editing = true
    },

    openBundleDialog ({ contract, selectedBundle }) {
      this.resetDialogs()
      this.focusedContract = Object.assign({}, contract)
      this.focusedBundle = Object.assign({}, selectedBundle)
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
      this.bundleDialog = true
    },

    closeBundleDialog (activitiesCreated) {
      if (activitiesCreated) {
        this.closeDialogsAtOrAbove(this.dialogId)
        this.shouldAddActivity = false
        this.bundleDialog = false
        this.editActivities({ ...this.focusedContract })
      } else {
        this.resetDialogs()
      }
    },

    async deleteItem () {
      await this.deleteContract(this.focusedContract.contractId)
      this.resetDialogs()
      await this.refreshContracts()
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.dialog = false
      this.editing = false
      this.deleting = false
      this.creating = false
      this.contractDetail = false
      this.shouldAddActivity = false
      this.bundleDialog = false
      this.importingByproductTickets = false
      this.focusedTractId = -1
      this.focusedContract = {}
      this.focusedBundle = undefined
    },

    async save (createdContract) {
      this.resetDialogs()
      if (createdContract?.shouldAddActivity) {
        this.$nextTick(_ => {
          if (createdContract.selectedBundle) {
            this.openBundleDialog(createdContract)
          } else {
            this.editActivities(createdContract.contract)
          }
        })
        setTimeout(() => {
          this.shouldAddActivity = false
        }, 3000)
      }
    },

    userSettingFilterParams (userSettings) {
      if (this.contractMode.value !== 0) return {}

      const ts = new Set(userSettings[SETTING_TYPES.TABLE][SETTING_KEYS.CONTRACT_FILTER_TYPES])
      const typeParams = {
        includeProduction: ts.has(0),
        includeWoodsSale: ts.has(1)
      }

      return typeParams.includeProduction === false && typeParams.includeWoodsSale === false
        ? { includeProduction: true }
        : typeParams
    },

    setFilterForContractMode (val) {
      const filter = { ...this.filter }
      filter.includeProduction = false
      filter.includeByProduct = false
      filter.includeByproductPurchase = false
      filter.includeTransfer = false
      filter.includeLogYardSale = false
      filter.includeWoodsSale = false
      switch (val.value) {
        case ContractMode.Logs.value:
          filter.includeProduction = this.filter?.includeProduction ?? true
          filter.includeWoodsSale = this.filter?.includeWoodsSale ?? false
          if (!filter.includeProduction && !filter.includeWoodsSale) filter.includeProduction = true
          break
        case ContractMode.Byproducts.value:
          filter.includeByProduct = true
          filter.includeByproductPurchase = true
          break
        case ContractMode.Transfers.value:
          filter.includeTransfer = true
          break
        case ContractMode.LogYardSale.value:
          filter.includeLogYardSale = true
          break
      }
      this.setFilter(filter)
    }
  }
}
</script>
